(function ($, window, document, undefined) {

  'use strict';

  $(function () {

      var swiperMission = new Swiper ('.mission-swiper-container', {

          loop: true,
          autoplay: {
              delay: 5000,
          },
          effect: 'fade',
      });

      var swiperCreation = new Swiper ('.creation-swiper-container', {
          direction: 'horizontal',
          loop: true,
          autoplay: {
              delay: 5000,
          },
          // Navigation arrows
          navigation: {
              nextEl: '.creation-swiper-button-next',
              prevEl: '.creation-swiper-button-prev',
          },

      });

      $(document).on('click', 'a[href^="#"]', function (event) {
          event.preventDefault();

          $('html, body').animate({
              scrollTop: $($.attr(this, 'href')).offset().top
          }, 500);
      });

  });

})(jQuery, window, document);
